import React, { lazy } from "react";

import moment from "moment";

import { useWindowDimensions } from "utilities";
import { tablet } from "breakpoints";

const Card = lazy(() => import("components/Card"));
const NoteBox = lazy(() => import("components/NoteBox"));

const NotesCard = ({ activity, pressureTest, workspace, incidentReadings }) => {
    const { width, height } = useWindowDimensions();

    const incidentHtml = incidentReadings
        .filter((i) => i.note)
        .sort((a, b) => new Date(a.measuredAt) - new Date(b.measuredAt))
        .map(
            (reading) =>
                `<strong>${moment(reading.measuredAt).format(
                    "HH:mm:ss",
                )}</strong></br>${reading.note.body}`,
        )
        .join("</br></br>");

    return (
        <div className="notes">
            <Card>
                <div className="notes-card">
                    <h4 style={{ marginBottom: "20px" }}>Notes</h4>
                    <div className="notes-body">
                        <div className="notes-box">
                            <NoteBox
                                title={`${
                                    workspace?.knownAsLabel ?? "Installer"
                                } Notes`}
                                body={activity.noteBody}
                                className="notes-box-left"
                            />
                            <NoteBox
                                title={"Dispute Notes"}
                                body={pressureTest.disputeReason}
                                className="notes-box-left"
                            />
                        </div>
                        <NoteBox
                            title={"Incident Notes"}
                            html={incidentHtml}
                            style={
                                width > tablet
                                    ? {
                                          height: "412px",
                                          width: "50%",
                                          paddingLeft: "8px",
                                      }
                                    : { width: "100%" }
                            }
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default NotesCard;
